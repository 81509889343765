/**
 * Created by panwei on 2017/9/14.
 */

export default [
  {
    point: '@@layers',
    prefix: '@apportion',
    onload: () => import('./layers')
  },
  {
    point: '@@components',
    namespace: '@apportion',
    onload: () => [{ key: 'Apportion', component: () => import('./Apportion') }]
  }
]
